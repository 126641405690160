.related-rent-gym-product-container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    background-color:#F5F5F5;
}

.related-rent-gym-product-block{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 185px;
    height: 270px;
    margin-left: 15px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
}

.related-rent-gym-product-image{
    margin-top: 18px;
    margin-left: 40px;
}

.related-rent-gym-product-title{
    margin-top: 7px;
    font-size: 15px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}
.related-rent-gym-product-price{
    display: flex;
    flex-direction: row;
    width: 190px;
    gap: 60px;
    margin-top: 10px;
}
.related-rent-gym-price1{
    margin-left: 20px;
   
}
.related-rent-gym-product-buy{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-left: 10px;
}
.related-rent-gym-product-buy-amount{
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-left: 10px;
    text-decoration: line-through;
    text-decoration-color: red;

}
.related-rent-gym-price2{
    font-size: 12px;
    margin-left: -20px;
    line-height: 14px;
    color:#000000;
    width: 0px;
}
.related-rent-product-button{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 20px;
    width: 124px;
    margin-left: 26px;
}
.product-rent-discover-button{
    flex: 1;
    font-weight: bold;
    width: 110px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border: 1px solid #D1D4E5;
    background-color: #45496A;
    color: white;
}
.product-discover-button:hover {
    /* background-color: #F4F4F4;
    border: none;
    color: black;
    border: 1px solid #D1D4E5; */
  }