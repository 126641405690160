.logo-container{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #E15456;
}
.logo{
    margin-top: 43pc;
    position: relative;
    bottom: 21pc;
    width: 379px;
    height: 119px;
}