.discription-header-container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-around; */
}

.discription-header-icon {
  /* display: flex; */
  flex: 0.5;
  width: 40px;
  height: 40px;
  margin-top: 45px;
  margin-left: 15px;
}

.discription-header-title {
  /* display: flex; */
  flex: 1;
  font-size: 22px;
  font-weight: 700;
  color: #E15456;
  margin-top: 53px;
}

.product-image-gallery {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

img {
  display: block;
  cursor: pointer;
}
.imgContainer{
  /* opacity: 1; */
}

.product-image-gallery-container {
  margin: auto;
  width: 100%;
}

.product-image-gallery-container .selected {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  /* opacity: 0.2; */
}

.product-image-gallery-container .imgContainer {
  width: 90%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-top: 20px;
}

.product-image-gallery-container .imgContainer img {
  width: 100px;
  height: 100px;
  margin: 5px 0;
}

.selected {
  opacity: 2;
}