.cart-select-item-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.p-container {
  display: flex;
  flex-direction: row;
}
.delete {
  display: flex;
  justify-content: center;
  background-color: #45496a;
  height: 89px;
  width: 88px;
  margin-top: 10px;
  margin-right: 15px;
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}
.delete-icon {
  width: 25px;
  height: 25px;
  margin-top: 30px;
}
.delete:hover {
  display: none;
}
.cart-select-item-block {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* height: 90px; */
  background-color: #f0f0f0;
  border-radius: 9px;
  margin: 10px 15px 0px 15px;
}

.cart-select-item-image {
  margin-top: 18px;
}

.cart-select-items {
  display: flex;
  flex-direction: column;
  margin-top: 21px;
}
.cart-select-item-title {
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  width: 150px;
}
.cart-select-item-price {
  margin-top: 6px;
  font-size: 12px;
  font-weight: 600;
  color: #666;
}

.cart-select-item-no {
  display: flex;
  flex-direction: row;
  margin-top: 38px;
  gap: 7px;
}
.cart-select-decrease {
  border: none;
  margin-bottom: 30px;
}

.cart-select-increase {
  border: none;
  margin-bottom: 30px;
}
.cart-select-items-value {
  margin-top: 4px;
}
