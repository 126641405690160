.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  border: 1px solid #a7a7a7;
  margin: 0px;
  height: 90%;
  background: #fff;
}

.login-logo {
  display: flex;
  margin-bottom: 20px;
  width: 75%;
}

.login-subtitle {
  font-size: 16px;
  text-align: center;
  padding: 10px 0px;
  color: #a7a7a7;
  font-family: sans-serif;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-account-form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.login-account-form-group input:focus {
  outline: none;
}

.login-label {
  margin-bottom: 5px;
  font-weight: bold;
}

.log-input {
  padding: 10px;
  margin-bottom: 20px;
  width: 300px;
  height: 23px;
  border: 1px solid rgb(235, 231, 231);
  border-radius: 3px;
  outline: none;
}

.log-input-user-id {
  padding: 10px;
  margin-bottom: 20px;
  width: 270px;
  height: 23px;
  border: 1px solid rgb(235, 231, 231);
  border-radius: 3px;
  outline: none;
}

/* input[type="tel"],
  input[type="password"] {
    width: 100%;
    padding: 15px 55px;
    border: 1px solid rgb(236, 233, 233);
    border-radius: 3px;
    font-size: 16px;
  } */

.log-input::placeholder {
  color: rgb(180, 176, 176);
  font-size: 17px;
}

.login-forgot-password {
  font-size: 18px;
  margin-bottom: 20px;
  margin-top: 5px;
  text-align: center;
  color: #a7a7a7;
  text-decoration: none;
}

.login-get-me-back-button {
  padding: 10px 30px;
  background-color: #7566a3;
  color: #d4caf1;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.login-get-me-back-button:hover {
  background-color: darkpurple;
}

.login-signup {
  display: flex;
  gap: 5px;
}

.login-yet-sign {
  font-size: 15px;
  margin-top: 15px;
  text-align: center;
  color: #a7a7a7;
}

.login-signup-button {
  font-size: 20px;
  color: #7566a3;
  margin-top: 11px;
  text-align: center;
  font-weight: 500;
}

.login-signup-button:hover {
  cursor: pointer;
}

.login-or-line {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.login-line {
  flex-grow: 1;
  border: none;
  width: 150px;
  border-bottom: 1px solid #ccc;
}

.login-or-text {
  margin: 0 10px;
  color: #a7a7a7;
}

.login-social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.login-social-link {
  display: inline-block;
  margin: 0 5px;
  padding: 5px;
  text-decoration: none;
}


/* #otpless-login-page {
  box-shadow: none !important;
  height: 400px !important;
}
*/
#otpless {
  width: 250px !important;
  height: 10px !important;
  margin: 0px !important;
}

#otpless-whatsapp-logo {
  width: 250px !important;
  height: 120px !important;
  margin: 0px !important;
}