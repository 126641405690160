.sales-page-container {
    background: #F5F5F5;
}

.sales-page-offer-card-container {
    display: flex;

}

.sales-page-offer-card-content {
    flex: 1;
    margin-top: 97px;
    margin-left: 27px;
}

.sales-page-offer-card-image {
    margin-right: 42px;
    margin-top: 142px;
    text-align: center;
    width: 177px;
    height: 177px;
    background: #E8EAEC;
    box-shadow: -23px -23px 45px #FFFFFF, 28px 28px 50px rgba(13, 39, 80, 0.16), inset 26px 26px 48px rgba(13, 39, 80, 0.16), inset -31px -31px 43px rgba(255, 255, 255, 0.64);
    border-radius: 27px;
}

.sales-page-container-paragraph {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: #404667;
    margin-left: 16px;
    margin-top: 50px;
}

.sales-page-button {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #000000;
    border: 1px solid #D1D4E5;
    margin-left: 15px;
}

.slick-dots li button {
    color: green !important;
}
