.related-product {
  border: 1px solid green;
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  flex-direction: column;
}
.related-gym-product-container {
  background-color: #f5f5f5;
}

.related-gym-product-block {
  display: flex;
  flex-direction: row;
  /* width: 400px; */
  height: 164px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #f5f5f5;
}

.related-product-container-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* margin-left: 10px; */
  margin-top: 10px;
  background-color: #ffff;
  margin-bottom: 10px;
}
.related-product-title-section {
  flex: 1;
  margin-left: 10px;
  margin-top: 24px;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  color: #000000;
}
.related-product-discription-section {
  flex: 1;
  margin-top: 6px;
  font-size: 9px;
  font-weight: 300;
  color: #45496a;
  line-height: 14px;
  margin-left: 10px;
}
.related-product-price-section {
  flex: 1;
  margin-top: 9px;
  display: flex;
  flex-direction: row;
  width: 190px;
  gap: 60px;
  margin-bottom: 23px;
}
.related-product-price1 {
  /* margin-left: 20px; */
}
.related-product-buy {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05em;
  color: #c1c1c1;
  margin-left: 10px;
}
.related-product-buy-amount {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.05em;
  color: #c1c1c1;
  margin-left: 10px;
  text-decoration: line-through;
  text-decoration-color: red;
}
.related-product-price2 {
  font-size: 13px;
  margin-left: -20px;
  line-height: 14px;
  color: #000000;
  width: 0px;
}

.related-product-image-section {
  background-color: white;
  height: 143px;
  margin-top: 10px;
}
.related-product-image {
  margin-top: 16px;
  margin-bottom: 15px;
  margin-right: 10px;
  height: 86%;
  background-color: white;
}
