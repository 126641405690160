.product-discription-items-container{
    /* background-color: #F5F5F5; */
}
.product-discription-items-cards-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    margin-top: 30px;
}
.product-discription-items-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 30px;
    color:  #45496A;
    margin-left: 20px
}
.product-discription-items-card-block {
    display: flex;
    flex-direction: column;
}

.product-discription-items-image-container {
   
   
}
.product-discription-items-image{
    margin-left: 20px;
    width: 150px;
    height: 150px;
}
.slick-list{padding:0 20% 0 0 !important;}