.wishlist-header-container{
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
}

.wishlist-header-icon{
    flex: 1;
    width: 40px;
    height: 40px;
    margin-top: 45px;
    margin-left: 15px;
}

.wishlist-header-title{
    flex: 1;
    font-size: 22px;
    font-weight: 700;
    color: #E15456;
    margin-top: 50px;
    margin-right: 70px;
}

.wishlist-select-item-container{
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    height: 40pc;
    background-color: #F5F5F5;
}

.wishlist-select-item-block{
    display: flex;
    /* flex-direction: row; */
    /* width: 400px; */
    height: 140px;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 9px;
    margin-top: 10px;
    background-color: white;
    /* box-shadow:  rgba(0, 0, 0, 0.25) 2px 2px 9px; */
}

.wishlist-select-item-image{
    margin-top: 18px;
    margin-left: 20px;
}

.wishlist-select-items{
    display: flex;
    flex-direction: column;
    margin-top: 27px;
    margin-left: 15px;
    line-height: 30px;
}
.wishlist-select-item-title{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    margin-left: 15px;
    width: 150px;
}
.wishlist-select-item-price{
    margin-top: 0px;
    margin-left: 15px;
    font-size: 12px;
    font-weight: 400;
}
.wishlist-add-button{
    margin-left: 15px;
}

button[type="add"] {
    color: black;
    border: 0.5px solid #D1D4E5;
    width: 110px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
    cursor: pointer;
    background-color: white;
  }
  
  button[type="add"]:hover {
    background-color: white;
  }
