.home-page-header-container{
    position: sticky;
    top: 0px;
    z-index: 99999;
    background-color: white;
    height: 70px;
}
.homepage-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

        /* position: sticky;
        top: 0px;
        z-index: 99999; */
    
}

.home-logo-image {
    display: flex;
    margin-top: 30px;
    margin-left: 15px;
    width: 120px;
    height: 35px;
}

.header-location {
    display: flex;
    flex-direction: row;
    margin-top: 46px;
}

.location-title {
    display: flex;
    font-size: 15px;
    font-weight: 600;
    color: #E15456;
    margin-right: 10px;
}

.home-map-image {
    display: flex;
    width: 15px;
    height: 15px;
    margin-right: 15px;
}

.Home-browser-button{
    display: flex;
    margin-top: 20px;
}
button[type="click"] {
    background-color: #E15456;
    color: #fff;
    border: none;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 600;
    width: 430px;
    height: 35px;
    line-height: 0px;
    cursor: pointer;
  }
  
  button[type="click"]:hover {
    background-color: #E15456;
  }