.login-input {
    padding: 5px;
    margin: 10px;
    width: 80%;
    height: 29px;
    border: 1px solid #ccc;
    border-radius: 7px;
    text-align: center;
}

.log-input:focus {
    outline: auto;
}

#agreeCheckbox:focus {
    outline: auto;
}