.custom-dropdown {
    position: relative;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 30px;
    
  }
  .discription-item-color-container{
    /* background-color: #F5F5F5; */
  }
  
  .custom-dropdown label {
    display: block;
    /* margin-bottom: 8px; */
    margin-left: 15px;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
  }
  
  .selected-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px; */
    padding: 0px 20px;
    border-radius: 4px;
    border: 1px solid #E15456;
    cursor: pointer;
    width: 352px;
    height: 48px;
    margin-top: 30px;
    margin-left: 15px;
    margin-right: 15px;
  }
  
  .toggle-button {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
  
  .toggle-icon {
    margin-left: 8px;
  }
  
  .options {
    list-style: none;
    padding: 8px 15px;
    margin: 0;
    margin-left: 15px;
    margin-right: 15px;
    /* border: 1px solid #ccc; */
    background-color: #fff;
  }
  
  .options li {
    padding: 8px;
    cursor: pointer;
  }
  
  .options li:hover {
    background-color: #f2f2f2;
  }
  