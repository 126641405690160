.home-product-cards-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
}

.home-product-card-block {
    width: 114px !important;
    height: 120px;
    background:   #E8EAEC;
    border-radius: 27px;
    color: white;
    text-align: center;
    margin-left: 15px;
}
.product-image{
    margin-top: 25px;
    margin-left: 22px;
}
.product-title{
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    color:#45496A;
    margin-top: 38px;
    margin-left: 10px;
    width: 100px;
}
