/* SignUpForm.css */

.sign-up-form-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    margin-top: 20px;
  }
  .firstName{
    font-size: 15px;
    margin-top: 30px;
    margin-left: 15px;
  }
  .lastName{
    font-size: 15px;
    margin-top: 30px;
    margin-left: 15px;
  }
  .email{
    font-size: 15px;
    margin-top: 30px;
    margin-left: 15px;
  }
  .password{
    font-size: 15px;
    margin-top: 30px;
    margin-left: 15px;
  }
  
  .input-signup-search{
    display: flex;
    align-items: center;
    border: 2px solid #E15456;
    border-radius: 5px;
    margin: 10px 15px 0px 15px;
  }
  
  .input-signup-search input:focus {
    outline: none;
  }

  /* input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 83%;
    padding: 15px;
    border: 2px solid #E4857B;
    border-radius: 3px;
    font-size: 16px;
    margin-left: 15px;
  } */

  .sign-up-button{
    width: 91%;
    height: 60px;
    border-radius: 5px;
  }
  
  button[type="submit"] {
    background-color: #E15456;
    color: #fff;
    padding: 15px 0px 15px 0px;
    border: none;
    border-radius: 3px;
    font-size: 25px;
    font-weight: 600;
    margin: 20px 21px 0px 15px;
    cursor: pointer;
  }
  
  button[type="submit"]:hover {
    background-color: #E15456;
  }
  