.modal-container {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(128, 128, 128, 0.2);
    width: 500px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 300px;
    min-width: 300px;
    background: green;
    border-radius: 16px;
    height: 140px;
    padding: 20px 0px 0px 30px;
    color: white;
}

.modal-title {
    font-style: italic;
    font-size: 25px;
    margin: 10px 0px 10px 0px;
}

.modal-description {
    font-size: 18px;
    font-weight: medium;
    font-style: italic;
    margin: 0;
    word-wrap: normal;
    text-align: left;
    max-width: 270px;
}

@media screen and (width < 500px) {
    .modal-container {
        width: 100vw;
    }
}