.Home-browser-button {
  display: flex;
}
button[type="click"] {
  background-color: #e15456;
  color: #fff;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
  height: 35px;
  line-height: 0px;
  cursor: pointer;
}

button[type="click"]:hover {
  background-color: #e15456;
}
