.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #E15456;
    /* width: 430px; */
    height: 65px;
    cursor: pointer;
    /* margin-top: 75px; */

    position: fixed;
    width: 100%;
    bottom: 0;
    /* top: 520px; */
}


.footer-logo{
    margin-top: 18px;
}