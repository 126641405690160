.password-input-box {
  padding: 10px;
  margin-bottom: 0px;
  width: 300px;
  height: 23px;
  border: 1px solid rgb(235, 231, 231);
  border-radius: 8px;
  outline: none;
}

.password-reset-button {
  padding: 10px;
  background-color: #463d61;
  color: #d4caf1;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 12px;
}

.error-password {
  margin: 8px 0px 24px 200px;
  color: red;
  text-align: left;
  width: 500px;
}

.page {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: gray;
  align-items: center;
  justify-content: center;
}

.sub-page {
  height: 100%;
  max-width: 500px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-page-header {
  display: flex;
  padding: 20px;
  padding-left: 60px;
  padding-bottom: 0px;
  width: 500px;

}

@media screen and (width < 500px) {
  .page {
    width: 100vw;
    max-width: 100vw;
  }

  .sub-page {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    background: #fff;
  }

  .sub-page-header {
    display: flex;
    padding: 20px;
    padding-left: 60px;
    padding-bottom: 0px;
    width: 100vw;
    max-width: 100vw;
  }
}