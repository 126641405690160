.product-delivery-estimate-container{
    margin-top: 25px;
    margin-left: 15px;
    color:  #45496A;
    font-weight: 500;
    font-size: 15px;
    /* background-color: #F5F5F5; */
}

.product-delivery-estimate-content{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-left: -20px;
    color:  #45496A;
}