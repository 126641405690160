.profile-payment-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: 87px;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-left: 15px;
    margin-right: 15px;
}

.profile-payment-block1{
    display: flex;
    flex-direction: row;
}
.profile-payment-block2{
    display: flex;
    flex-direction: row;
}
.profile-payment-line{
    margin-top: 10px;
    border-bottom: 1px solid #E2E2E2;
}
.profile-payment-image{
    margin-left: 10px;
    margin-top: 10px;
}

.profile-payment-address{
    font-size: 12px;
    font-weight: 500px;
    line-height: 15px;
    color: #717171;
    margin-top: 13px;
    margin-left: 16px;
   
}