.sports-option-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  border: 1px solid blue;
}

.sports-option-card-block {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 60px;
  background: #e8eaec;
  border-radius: 13px;
  color: white;
  text-align: center;
  margin-left: 15px;
  margin-top: 70px;
}
.sports-option-image {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.sports-option-title {
  font-size: 8px;
  font-weight: 500;
  line-height: 9px;
  color: #45496a;
  margin-top: 20px;
  margin-left: 11px;
  width: 41px;
}
