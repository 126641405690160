.log-input-user-id {
  padding: 10px;
  margin-bottom: 5px;
  width: 270px;
  height: 23px;
  border: 1px solid rgb(235, 231, 231);
  border-radius: 8px;
  outline: none;
}

.send-otp-button {
  padding: 15px 40px;
  background-color: #463d61;
  color: #d4caf1;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 12px;
}

.error {
  margin: 0px 0px 20px 42px;
  color: red;
  text-align: left;
}

.page {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: gray;
  align-items: center;
  justify-content: center;
}

.sub-page {
  height: 100%;
  max-width: 500px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-page-header {
  display: flex;
  padding: 20px;
  padding-left: 60px;
  padding-bottom: 0px;
  width: 500px;

}

@media screen and (width < 500px) {
  .page {
    width: 100vw;
    max-width: 100vw;
  }

  .sub-page {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    background: #fff;
  }

  .sub-page-header {
    display: flex;
    padding: 20px;
    padding-left: 60px;
    padding-bottom: 0px;
    width: 100vw;
    max-width: 100vw;
  }
}