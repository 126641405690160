.product-description-content-block {
    margin-left: 15px;
}

.product-description-content {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 30px;
}

.product-description-image-container {
    display: flex;
}

.product-description-star-images {
    margin-top: 17px;
}

.product-description-image-content {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    margin-top: 21px;
    letter-spacing: 0.05em;
    margin-left: 20px;

}

.product-description-rent-container {
    display: flex;
}

.product-description-buy {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-top: 29px;
}

.product-description-buy-amount {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-top: 29px;
    margin-left: 3px;
    text-decoration: line-through;
    text-decoration-color: red;
}

.product-description-rent {
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #000000;
    margin-top: 29px;
    margin-left: 10px;
}