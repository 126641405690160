.search-product-cards-container {
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 15px; */
  gap: 10px;
  margin-top: 10px;
  justify-content: center;
  width: 85%;
}

.search-product-card-block {
  display: flex;
  flex-direction: column;
}

.search-product-container {
  width: 192px;
  height: 192px;
  /* background-color: #E15456; */
  border-radius: 9px;
}
.search-product-name {
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  margin-top: 10px;
  color: white;
  font-style: "bold";
  /* margin-left: 15px; */
  /* width: 165px; */
  padding: 10px;
}

.search-product-image {
  margin-top: 0px;
  margin-left: 58px;
}
