.tabs {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    margin-top: 30px;
    
  }
  
  .tab-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    /* justify-content: space-around; */
    /* margin-right: 30px; */
    
  }
  
  .tab-buttons button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px;
    margin-right: 10px;   
  }
  .tab-buttons li{
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.8);
  }
  
  .tab-buttons li.active {
    border-bottom: 2px solid  #45496A;
    list-style: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    color: #45496A;
  }
  
  .tab-content {
    line-height: 18px;
    font-size: 12px;
    font-weight: 400;
    color: #404667;
  }
  