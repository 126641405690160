.header-section {
  /* border: 1px solid black; */
  background: #fff;
  /* height: 100%; */
  align-items: center;
  flex: 1;
}
.sprentzo-logo {
  width: 49%;
}
.logo-section {
  padding-top: 40px;
  text-align: center;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
}
.image-section-2 {
  text-align: center;
  margin-top: 17px;
  display: flex;
  justify-content: center;
}
.sprentzo1 {
  width: 245px;
}
.tital {
  text-align: center;
}
.welcome {
  text-align: center;
  font-style: italic;
  color: #000000ba;
  margin: 0;
  padding-top: 11px;
}
.sub-tital-1 {
  text-align: center;
  margin: 0;
  color: #0000004a;
  font-size: 14px;
  font-weight: 600;
}
.sub-tital-2 {
  text-align: center;
  text-align: center;
  text-align: center;
  margin: 0;
  color: #0000004a;
  font-size: 14px;
  color: #0000004a;
}
.sign-me-button {
  padding-top: 50px;
  text-align: center;
}
.sing-me {
  width: 219px;
  height: 36px;
  border-radius: 7px;
  background-color: #0c1e85a6;
  color: #ffffffa6;
  border-color: #0c1e85a6;
  border: none;
  font-size: 14px;
  font-weight: 100;
  cursor: pointer;
}
.login {
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 4px;
}
.login-tital {
  color: #0000004a;
  font-size: 14px;
  color: #0000004a;
  padding-top: 5px;
}
.login-1 {
  color: #3f51b5;
  text-decoration: none;
  padding-left: 4px;
  font-size: 14px;
  font-weight: 500;
}
.login-1:hover {
  cursor: pointer;
}
.login-link {
  padding-top: 4px;
}
