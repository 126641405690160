.related-option-title-container{
    display: flex;
    flex-direction: row;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 50px;
}

.related-option-title-block{
    flex: 2 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 75px;
    margin-bottom: 20px;
}
.related-option-text{
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    align-items: center;
    color:  #45496A;
}

.related-option-text:hover {
    border-bottom: 1px solid red;
}

.related-option-image-block{
    flex: 0.5;
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.tab-img-buttons {
    display: flex;
    gap: 10px;
    margin-top: 0px;
    
  }
  .tab-img-buttons li {
    list-style: none;
  }

  .related-option-grid-image{
    display: block;
  }