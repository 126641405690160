.cart-header-container{
    display: flex;
    flex-direction: row;
}

.cart-header-icon{
    flex: 0;
    width: 40px;
    height: 40px;
    margin-top: 45px;
    margin-left: 15px;
}

.cart-header-title{
    flex: 0.5;
    font-size: 22px;
    font-weight: 700;
    color: #E15456;
    margin-top: 50px;
    margin-left: 135px;
}