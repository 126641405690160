.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;

  margin: 30px 15px 0px 15px;
}

.search-bar input {
  border: none;
  background-color: transparent;
  margin-left: 10px;
  font-size: 16px;
  width: 100%;
}

.search-bar-search-icon {
  margin-left: 10px;
}
.search-bar-history-icon {
  margin-right: 10px;
}
.search-bar input:focus {
  outline: none;
}

.search::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  font-weight: 600;
  opacity: 1; /* Firefox */
}

.search:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
  font-weight: 600;
}

.search::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
  font-weight: 600;
}
