.otp-input-user-id {
  padding: 8px;
  width: 315px;
  height: 23px;
  border: 1px solid rgb(235, 231, 231);
  border-radius: 8px;
  outline: none;
}

.verify-otp-button {
  padding: 0px 5px;
  background-color: #463d61;
  color: #d4caf1;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin: 0px;
}

.wrong-phone-number-link {
  margin: 15px 0px;
  color: #7566a3;
  font-weight: bold;
}

.verify-otp-button:hover {
  background-color: #463d61;
}

.verify-otp-button-arrow {
  padding: 5px 0px;
  margin: 0px;
}

.error-otp {
  margin: 8px 0px 20px 140px;
  color: red;
  text-align: left;
  width: 500px;
}

.page {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: gray;
  align-items: center;
  justify-content: center;
}

.sub-page {
  height: 100%;
  max-width: 500px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sub-page-header {
  display: flex;
  padding: 20px;
  padding-left: 60px;
  padding-bottom: 0px;
  width: 500px;

}

@media screen and (width < 500px) {
  .page {
    width: 100vw;
    max-width: 100vw;
    overflow: hidden;
  }

  .sub-page {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    background: #fff;
  }

  .sub-page-header {
    display: flex;
    padding: 20px;
    padding-left: 60px;
    padding-bottom: 0px;
    width: 100vw;
    max-width: 100vw;
  }

  .otp-input-user-id {
    padding: 8px;
    width: calc(100vw - 100px);
    height: 23px;
    border: 1px solid rgb(235, 231, 231);
    border-radius: 8px;
    outline: none;
  }

  .error-otp {
    width: calc(100vw - 45px);
    margin: 8px 0px 0px 0px;
  }
}