.social-app-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-direction: row;
  margin-top: 30px;
}

.social-app-horizontal-line {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
}
.hr-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 0px;
  color: #e15456;
}
.hr-left-line {
  border-bottom: 1px solid #e15456;
  flex: 1;
  margin-bottom: 18px;
  margin-left: 15px;
}
.hr-right-line {
  border-bottom: 1px solid #e15456;
  flex: 1;
  margin-bottom: 18px;
  margin-right: 15px;
}

.sign-social-logo {
  border-radius: 7px;
  border: none;
  background: #ffffff;
  box-shadow: 0px 3px 9px -2px #555555;
  margin-top: 5px;
  line-height: 60px;
  text-align: center;
  width: 60px;
  height: 60px;
}
.social-logo {
  margin-top: 8px;
  margin-left: 6px;
}
