.search-list-content-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.search-list-content-block{
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-left: 15px;
    margin-top: 10px;
}

.search-list-content-title{
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color:#45496A;
}