.orderpage {
    background-image: url("../OrderPlaced/images/bg-image.png");
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
}

.blurrimage {
    background-image: url("../OrderPlaced/images/blurr-image.png");
    background-repeat: no-repeat;
    /* background-size: cover; */
    background-attachment: fixed;
}

.order-container {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.image-container {
    background-image: url("../OrderPlaced/images/delivery-image.png");
    background-repeat: no-repeat;
    height: 196px;
    width: 272px;
    margin-top: 141px;
}

.title {
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 46px;
    text-align: center;
    line-height: 21px;
}

.subtitle {
    line-height: 36px;
    letter-spacing: 0.05em;
    font-weight: 500;
    font-size: 20px;
}

.integer {
    line-height: 36px;
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 20px;
}

.subtitle-1 {
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-align: center;
    line-height: 22px;
    margin: 20px 15px 0px 15px;
}
.subtitle-container{
    margin-top: 20px;
}