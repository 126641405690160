.login-form-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
}
.login-form-email {
  font-size: 15px;
  margin-top: 30px;
  margin-left: 15px;
}
.login-form-password {
  font-size: 15px;
  margin-top: 30px;
  margin-left: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.input-login-search {
  display: flex;
  align-items: center;
  border: 2px solid #e15456;
  border-radius: 5px;
  margin: 10px 15px 0px 15px;
}

.input-login-search input:focus {
  outline: none;
}

/* input[type="text"],
input[type="email"],
input[type="password"] {
  width: 83%;
  padding: 15px;
  border: 2px solid #e4857b;
  border-radius: 3px;
  font-size: 16px;
  margin-left: 15px;
} */
.log-in-button {
  width: 91%;
  height: 60px;
  border-radius: 5px;
}
button[type="submit"] {
  background-color: #e15456;
  color: #fff;
  padding: 15px 0px 15px 0px;
  border: none;
  border-radius: 3px;
  font-size: 25px;
  font-weight: 600;
  margin: 20px 21px 0px 15px;
  cursor: pointer;
  width: 94%;
}

button[type="submit"]:hover {
  background-color: #e15456;
}
