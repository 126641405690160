.search-list-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 270px;
}

.search-list-block{
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-left: 15px;
    margin-top: 25px;
}

.search-list-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    color:#45496A;
}

.search-list-sub-title{
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    color:#45496A;
}