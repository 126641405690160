.signbutton-container {
  display: flex;
  flex-direction: row;
}

.homeStyle {
  width: 200px;
  height: 48px;
}

.signbutton-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-left: 15px;
  margin-right: 15px;
}

.signbutton-container a {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  width: 197px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  border-bottom: 1px solid #E15456;

}

.signbutton-container a:hover {
  background-color: #E15456;
  color: black;
  cursor: pointer;
}

.signup-button {
  flex: 1;
  background-color: white;
  font-weight: bold;
  width: 197px;
  height: 42px;
  margin-left: 15px;
  margin-top: 30px;
  font-size: 18px;
  font-weight: 700;
  color: #E15456;
  border-radius: 5px;
  border: none;
  /* border: 1px solid #E15456; */
  border-bottom: 1px solid #E15456;
}

/* .signup-button:hover {
  background-color: #E15456;
  border-color: #E15456;
  border: none;
  color: white;
} */

.login-button {
  flex: 1;
  background-color: #E15456;
  border-color: #E15456;
  font-weight: bold;
  width: 197px;
  height: 42px;
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
  margin-right: 15px;
  border-radius: 5px;
  background: none;
  border: none;
  color: #E15456;
  border-bottom: 1px solid #E15456;
}