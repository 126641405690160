.profile-section-logo{
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.profile-section-container{
    display: flex;
    height: 71px;
    background-color: #F4F4F4;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 9px;
    margin-top: 30px;
}

.profile-section-image{
    margin-top: 7px;
    margin-left: 10px;
}

.profile-section-details{
    margin-left: 25px;
    margin-top: 15px;
}
.profile-section-name{
    color: #45496A;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
.profile-section-phone-number{
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    margin-top: 5px;
}

.profile-subscribe-section{
    display: flex;
    flex-direction: column;
}
.profile-section-para{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin-left: 35px;
    margin-right: 41px;
    margin-top: 30px;
}
.profile-button-container{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    gap: 20px;
    margin-left: 95px;
    margin-right: 95px;
}

.profile-create-button{
    flex: 1;
    /* background-color: white; */
    font-weight: bold;
    width: 110px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
    border: none;
    border: 1px solid #D1D4E5;
    background-color: #45496A;
    color: white;
}
.profile-create-button:hover {
    background-color: #F4F4F4;
    border: none;
    color: black;
    border: 1px solid #D1D4E5;
  }

  .profile-subscribe-button {
    flex: 1;
    /* background-color: white; */
    font-weight: bold;
    width: 110px;
    height: 34px;
    font-size: 12px;
    font-weight: 500;
    background: none;
    border: none;
    border: 1px solid #D1D4E5;
    background-color: #45496A;
    color: white;
  }
  
  .profile-subscribe-button:hover {
    background-color: #F4F4F4;
    border: none;
    color: black;
    border: 1px solid #D1D4E5;
  }   