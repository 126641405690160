.home-new-product-cards-container {
  /* display: flex; */
  /* flex-wrap: wrap; */
}
.Home-product-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-top: 40px;
  color: #e15456;
  font-style: "bold";
  margin-left: 15px;
}
.home-new-product-card-block {
  display: flex;
  flex-direction: column;
}

.new-product-image-container {
  margin-top: 40px;
  margin-left: 15px;
  width: 234px;
  height: 247px;
  margin-bottom: 45px;
  /* background-color: #E8EAEC; */
  background-color: #fefefe;
  border-radius: 9px;
  /* box-shadow: rgb(0 0 0 / 26%) 6px 7px 17px 5px; */

  box-shadow: 3px 3px 14px rgb(0 0 0 / 25%);
  border-radius: 27px;
}
.new-product-image {
  margin-left: 47px;
  padding: 50px 2px;
  /* width: 130px;
    height: 130px; */
}
.slick-list {
  padding: 0 20% 0 0 !important;
}
