.product-details-stock-title{
    font-weight: 500;
    font-size: 15px;
    color: #4BAF61;
    margin-left: 15px;
    margin-top: 20px;
}
.radio-buttons {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-top: 25px;
  }
  
  .radio-buttons label {
    margin-bottom: 10px;
    border: 1px solid red;
    border-radius: 9px;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
    /* height: 55px; */
    line-height: 58px;
    padding: 0px 20px 0px 20px;
    color:  #E15456;
    font-size: 18px;
    font-weight: 400;

  }
  
  

  .radio-buttons .radio-button-input {
    /* Hide the default radio button */
    display: none;
  }
  
  .radio-buttons .radio-button-custom {
    /* Unchecked color */
    display: inline-block;
    vertical-align: middle;
    border: 3px solid #E15456;
    border-radius: 50%;
    width: 33px;
    height: 33px;
    margin-top: 10px;
    margin-right: 10px;
  }
  
  .radio-buttons .radio-button-input:checked + .radio-button-custom {
    /* Checked color */
    border-color: #E15456;
    background-color: #E15456;
  }
  