.home-similar-product-title-container {
    display: flex;
    flex-direction: row;
    margin-top: -35px;
}

.Home-similar-product-title {
    flex: 1;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    margin-top: 40px;
    color: #E15456;
    font-style: 'bold';
    margin-left: 15px
}

.Home-similar-product-subtitle {
    flex: 1;
    margin-top: 40px;
    text-align: end;
    margin-right: 20px;
}
.Home-similar-product-subtitle:hover {
    color: #E15456;
}

.home-similar-product-block {
    width: 187px !important;
    height: 220px;
    background: #FFFFFF;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.25);
    margin-top: 30px;
    margin-bottom: 55px;
    margin-left: 15px;
}

.home-similar-product-image {
    margin-top: 18px;
    margin-left: 40px;
}

.home-similar-product-title {
    margin-top: 20px;
    margin-left: 58px;
    font-size: 15px;
    font-weight: 500;
    margin-right: 20px;
}

.home-similar-product-price {
    display: flex;
    flex-direction: row;
    /* justify-content: space-around; */
    gap: 60px;
    margin-top: 10px;
}

.product-price1 {
   margin-left: 35px;
}
.product-buy{
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-left: 10px;
}
.product-buy-amount{
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 0.05em;
    color: #C1C1C1;
    margin-left: 10px;
    text-decoration: line-through;
    text-decoration-color: red;

}
.product-price2 {
    font-size: 12px;
    margin-left: -23px;
    /* margin-right: 45px; */
    line-height: 14px;
    color: #000000;
    width: 0;
}

.home-similar-product-cards-container {
    /* display: flex; */
    /* flex-wrap: wrap; */
    background: #E8EAEC;
}

.home-similar-product-card-block {
    width: 114px !important;
    height: 120px;
    border-radius: 27px;
    color: white;
    text-align: center;
    margin-left: 15px;
    /* margin-top: 40px; */
}

.similar-product-image {
    margin-top: 27px;
    margin-left: 26px;
}

.similar-product-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    color: #45496A;
    margin-top: 38px;
    margin-left: 10px;
    width: 100px;
}