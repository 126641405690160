.profile-setting-container{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: 100%;
    border-radius: 5px;
    background-color: #F4F4F4;
    margin-left: 15px;
    margin-right: 15px;
}

.profile-setting-block1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
}
.profile-setting-icon{
    flex: 1;
    margin-left: 10px;
    margin-top: 10px;
}
.profile-setting-image{
    flex: 1;
    margin-left: 10px;
    margin-top: 10px;
}

.profile-setting-address{
    flex: 8;
    font-size: 12px;
    font-weight: 500px;
    line-height: 15px;
    color: #717171;
    margin-top: 13px;
    /* margin-right: 30px; */
   
}
.profile-setting-line{
    margin-top: 10px;
    border-bottom: 1px solid #E2E2E2;
}

.profile-setting-logout{
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    margin-left: 15px;
    /* height: 110px; */
}

.profile-logout-image{
    margin-left: 10px;
    margin-top: 10px;
}

.profile-logout-text{
    font-size: 12px;
    font-weight: 500px;
    line-height: 15px;
    color: #717171;
    margin-top: 13px;
    margin-left: 20px;
}